<template>
  <div>
    <div @click="handleRun">运行</div>
  </div>
</template>

<script setup>
class DiffSandbox {
  constructor(name) {
    this.name = name
    this.modifiedProps = {}
    this.windowSnapshot = {}
  }

  activeSandbox() {
    this.windowSnapshot = {}
    for (let key in window) {
      this.windowSnapshot[key] = window[key]
    }

    Object.keys(this.modifiedProps).forEach(propName => {
      window[propName] = this.modifiedProps[propName]
    })
  }

  inactiveSandbox() {
    for (let key in window) {
      if (this.windowSnapshot[key] !== window[key]) {
        this.modifiedProps[key] = window[key]
        window[key] = this.windowSnapshot[key]
      }
    }
  }
}
const handleRun = () => {
  // diff 沙箱测试
  const diffSandbox = new DiffSandbox('diff沙箱')
  // 激活沙箱
  diffSandbox.activeSandbox()
  const code = `function raw2Protocol(b) {
      
   return strToBytes(b);
  }`
  const fun1 = `
  function strToBytes (a) {
  a++
  return a
}
  `
  window.eval(fun1);
  window.eval(code);

  const result = window.raw2Protocol(2);
  console.log(result);



  // window.cat = '1'
  // console.log('激活沙箱', window.cat) // 1

  // 关闭沙箱
  // diffSandbox.inactiveSandbox()
  // console.log('关闭沙箱', window.cat) // undefined

  // diffSandbox.activeSandbox()
  // console.log('重新激活沙箱', window.cat) // 1
}
</script>